import { navigate } from "gatsby";

export function scrollToId(id) {
    let element = document.getElementById(id);

    if (!element) {
        navigate(`/#${id}`);

        return;
    }

    setTimeout(
        () => element.scrollIntoView({
            behavior: 'smooth'
        }),
        100 // wait until modal closes
    )
}