import React from "react"
import * as styles from "./modal-age-consent.module.scss"
import { Modal } from "react-bootstrap";
import NeonLogoSVG from "../../svg/neon-logo.svg";
import { Link } from "gatsby";

export default function ModalAgeConsent(props) {

  function onCloseClick() {
    props.onCloseClick();
  }

  function onYesClick() {
    props.onYesClick();
  }

  function onNoClick() {
    props.onNoClick();
  }

  return <>
    <Modal
      show={props.show}
      fullscreen="true"
      onHide={() => onCloseClick()}
      dialogClassName={styles.modalDialogX}
      contentClassName={styles.modalContentX}
    >
      <Modal.Body>
        <div
          className={styles.listWrapper}
          // onClick={() => onCloseClick()}
        >
          <div className={styles.listItem}>
            <div className={styles.logoWrapper}>
              <NeonLogoSVG />
            </div>
          </div>
          <div className={styles.listItem}>
            <div>
              <div className={styles.textTop}>
                Czy masz ukończone 18 lat?
              </div>
              <div className={styles.textBottom}>
                Are you over the age of 18?
              </div>
            </div>
          </div>
          <div className={styles.listItem}>
            <div className={styles.answerWrapper}>
              <div 
                className={`${styles.answer} ${styles.answer}`}
                onClick={() => onNoClick()}
              >
                Nie / No
              </div>
              <div 
                className={`${styles.answer} ${styles.answer}`}
                onClick={() => onYesClick()}
              >
                Tak / Yes
              </div>
            </div>
          </div>
          <div className={styles.listItem}>
            <div className={styles.cookiesInfo}>
              <div className={styles.cookiesInfoText}>
              Ta witryna wykorzystuje pliki cookies. 
              </div>
              
                <Link 
                  className={styles.moreInfo}
                  to="/privacy"
                >
                  Więcej informacji
                </Link>
            </div>
          </div>
        </div>

      </Modal.Body>
    </Modal>
  </>
}
