// extracted by mini-css-extract-plugin
export var answer = "modal-age-consent-module--answer--ItcYP";
export var answerWrapper = "modal-age-consent-module--answer-wrapper--f-WgW";
export var btnClose = "modal-age-consent-module--btn-close--vLpzX";
export var buttonWrapper = "modal-age-consent-module--button-wrapper--PAKTK";
export var closeButton = "modal-age-consent-module--close-button--q1SFo";
export var closeButtonWrapper = "modal-age-consent-module--close-button-wrapper--AlrGj";
export var cookiesInfo = "modal-age-consent-module--cookies-info--EIPPw";
export var cookiesInfoText = "modal-age-consent-module--cookies-info-text--TYTou";
export var listItem = "modal-age-consent-module--list-item--5L+zm";
export var listWrapper = "modal-age-consent-module--list-wrapper--MGXWt";
export var logoWrapper = "modal-age-consent-module--logo-wrapper--1CtwG";
export var modalContentX = "modal-age-consent-module--modal-content-x--jFhdB";
export var modalDialogX = "modal-age-consent-module--modal-dialog-x--Jyk5u";
export var moreInfo = "modal-age-consent-module--more-info--R8aR4";
export var textBottom = "modal-age-consent-module--text-bottom--quwUm";
export var textTop = "modal-age-consent-module--text-top--L+SaG";