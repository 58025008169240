import React, { useEffect } from "react";
import * as styles from "./menu.module.scss";
import LogotypeSVG from "../../svg/browar_kolo_logo.svg";
import {Link} from "gatsby";

export default function Menu(props) {

  const [isOnTop, setIsOnTop] = React.useState(true);
  const [isOnHomepage, setIsOnHomepage] = React.useState(true);

  React.useEffect(() => {
    const callback = () => {
      const aboveThreshold = window.scrollY < 100
      if (aboveThreshold !== isOnTop) {
        setIsOnTop(aboveThreshold);
      }
    };

    window.addEventListener("scroll", callback);

    return () => document.removeEventListener("scroll", callback);
  });

  useEffect(() => {
    setIsOnHomepage(window.location.pathname === '/');
  }, []);

  const containerStyles = isOnTop ? styles.container : `${styles.container} ${styles.containerScrolled}`;
  const logotypeStyles = isOnTop && isOnHomepage ? styles.logotype : `${styles.logotype} ${styles.logotypeScrolled}`;

  return <header>
    <div className={containerStyles}>

      <div className={styles.left}>
        {/* todo - homepage link */}
        <Link
          to="/" 
          className={logotypeStyles}
          aria-label="logo - navigate to home page"
        >
          <LogotypeSVG />
        </Link>
      </div>

      <nav className={styles.rightDesktop}>
        {props.menuList.map((item, index) =>
          <div
            className={`skip-link ${styles.desktopLink}`}
            onClick={() => item.onClick()}
            key={index}
          >
            {item.text}
          </div>
        )}
      </nav>

      <div className={styles.rightMobile} onClick={() => props.onMobileMenuClick()}>
        <span className={`icon-hamburger-menu-icon ${styles.menuIcon}`}></span>
      </div>

    </div>
  </header>
}
