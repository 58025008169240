import React, { useEffect } from "react"
import * as styles from "./layout.module.scss"
import { Helmet } from "react-helmet"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import Menu from "../menu/menu";
// import Offcanvas from "../offcanvas/offcanvas";
import ModalMenu from "../modal-menu/modal-menu";
import FooterSection from "../../page-sections/footer-section/footer-section";
import { scrollToId } from '../../global-functions/scroll-to';
import ModalAgeConsent from '../../components/modal-age-consent/modal-age-consent';
import Cookies from 'universal-cookie';
import { navigate } from "gatsby";

const AGE_COOKIE_NAME = 'bannerClosed';

export const MeetUsId = 'meet-us-section';
export const OurBeersId = 'our-beers-section';
export const RestaurantId = 'restaurant-section';
export const ContactSectionId = 'contact-section';

export default function Layout({ children }) {

  const [isOffcanvasOpen, setIsOffcanvasOpen] = React.useState(false);
  const [isOver18, setIsOver18] = React.useState(false);
  const [isOnPrivacyPage, setIsOnPrivacyPage] = React.useState(false);

  useEffect(() => {
    setIsOnPrivacyPage(window.location.pathname === "/privacy");
  }, []);

  function openOffcanvas() {
    setTimeout(() => {
      setIsOffcanvasOpen(true);
    }, 10);
  }

  function closeOffcanvas() {
    setIsOffcanvasOpen(false);
  }

  function onOffcanvasLinkClick(id, isPage) {
    closeOffcanvas();
    setTimeout(() => {
      if (!isPage) {
        scrollToId(id);
        return;
      }
      navigate(id);
    }, 150);
  }

  function wasCookieEstablished() {
      const cookies = new Cookies();
      return  !!cookies.get(AGE_COOKIE_NAME);
  }

  function onYesClick() {
    const cookies = new Cookies();
    cookies.set(AGE_COOKIE_NAME, true, {path: '/'});

    setIsOver18(true);
  }

  function onNoClick() {
    window.location.replace('https://beefteka.pl');
  }

  function onRestaurantClick() {
    window.location.replace('https://beefteka.pl');
  }

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <html lang="pl" />
      <title>Browar Koło</title>
      <meta name="description" content="Z pasji do piwa" />
        <meta name="theme-color" content="#242424"></meta>
    </Helmet>

    {
      isOnPrivacyPage || isOver18 || wasCookieEstablished() 
        ? <></> 
        : <> 
          <ModalAgeConsent 
            show={true}
            onYesClick={() => onYesClick(true)}
            onNoClick={() => onNoClick()}
          />
        </>
        
    }
    <div className={styles.container}>
      <Menu
        onMobileMenuClick={() => openOffcanvas()}
        menuList={[
          {
            text: "Poznaj nas",
            onClick: () => { scrollToId(MeetUsId); }
          }, {
            text: "Nasze Piwa",
            onClick: () => { scrollToId(OurBeersId); }
          }, {
            text: "Restauracja",
            onClick: () => { onRestaurantClick(); }
          }, {
            text: "Kontakt",
            onClick: () => { navigate("/contact"); }
          },
        ]}
        topBarItems={[
        ]}
      />
      <main>
        {children}
      </main>
      <FooterSection
        menuList={[
          {
            text: "Poznaj nas",
            onClick: () => { scrollToId(MeetUsId); }
          }, {
            text: "Nasze Piwa",
            onClick: () => { scrollToId(OurBeersId); }
          }, {
            text: "Restauracja",
            onClick: () => { onRestaurantClick(); }
          }, {
            text: "Kontakt",
            onClick: () => { navigate("/contact"); }
          },
        ]}
      />
      <span id={ContactSectionId}></span>
    </div>

    <ModalMenu
      show={isOffcanvasOpen}
      onCloseClick={() => closeOffcanvas()}
      menuList={[
        {
          text: "Poznaj nas",
          onClick: () => { onOffcanvasLinkClick(MeetUsId); }
        }, {
          text: "Nasze Piwa",
          onClick: () => { onOffcanvasLinkClick(OurBeersId); }
        }, {
          text: "Restauracja",
          onClick: () => { onRestaurantClick(); }
        }, {
          text: "Kontakt",
          onClick: () => { onOffcanvasLinkClick("/contact", true); }
        },
      ]}
    />
  </>
}
