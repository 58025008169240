import React from "react"
import * as styles from "./modal-menu.module.scss"
import { Modal } from "react-bootstrap";
import LogotypeSVG from "../../svg/browar_kolo_logo.svg";

export default function ModalMenu(props) {

  function onCloseClick() {
    props.onCloseClick();
  }

  return <>
    <Modal
      show={props.show}
      fullscreen="true"
      onHide={() => onCloseClick()}
      dialogClassName={styles.modalDialogX}
      contentClassName={styles.modalContentX}
    >
      <Modal.Body>
        <div className={styles.buttonWrapper}>
          <span
            className={`icon-close-icon ${styles.btnClose}`}
            onClick={() => onCloseClick()}
          ></span>
        </div>

        <div
          className={styles.listWrapper}
          onClick={() => onCloseClick()}
        >
            <div className={styles.listItem}>
              <div className={styles.logoWrapper}>
                <LogotypeSVG />
              </div>
            </div>
          {props.menuList.map((item, index) =>
            <div 
              className={styles.listItem} 
              onClick={() => item.onClick()}
              key={index}
            >
              {item.text}
            </div>
          )}
        </div>

      </Modal.Body>
    </Modal>
  </>
}
