import React from "react"
import * as styles from "./footer-section.module.scss"
import {Container, Row, Col} from "react-bootstrap";
import VerticalSpacingRow from "../../components/vertical-spacing/vertical-spacing";
import MainLogoSVG from "../../svg/browar_kolo_logo.svg";

import {Link} from "gatsby";
import VerticalSeparator from "../../svg/vertical-separator.svg";
import NeonSeparatorLeft from "../../svg/neon-separator-left.svg";
import NeonSeparatorRight from "../../svg/neon-separator-right.svg";


export default function FooterSection(props) {
    return <>
        <span id="footer-section"></span>
        <div className={styles.wrapper}>

            <VerticalSpacingRow/>

            <div className={styles.neonSeparatorWrapper}>
                <div className={styles.neonSeparatorLeft}>
                    <NeonSeparatorLeft/>
                </div>
                <div className={styles.neonSeparatorRight}>
                    <NeonSeparatorRight/>
                </div>
            </div>
            {/* <VerticalSpacingRow /> */}

            <Container>
                <VerticalSpacingRow/>
                <Row className="justify-content-around" center="xs">
                    <Col md={{span: 2, order: 1}} xs={{span: 12, order: 1}}>
                        <div className={styles.logoWrapper}>
                            <MainLogoSVG/>
                        </div>
                    </Col>
                    <Col md={{span: 9, offset: 1, order: 2}} xs={{span: 12, order: 2}}>
                        <Row>
                            <Col
                                md={4}
                                // xs={{ span: 6 }}
                            >
                                <div className={styles.columnHeader}>MAPA STRONY</div>

                                {props.menuList.map((item, index) =>
                                    <div
                                        className={`${styles.columnLink}`}
                                        onClick={() => item.onClick()}
                                        key={index}
                                        // onKeyDown={() => item.onClick()}
                                    >
                                        <span>{item.text}</span>
                                    </div>
                                )}
                            </Col>
                            <Col md={4}>
                                <div className={styles.columnHeader}>INFORMACJE</div>
                                <div className={styles.columnLink}>
                                    <Link to="/privacy">Polityka prywatności</Link>
                                </div>
                            </Col>

                            <Col
                                md={4}
                                // xs={{ span: 6 }}
                            >
                                <div className={styles.columnHeader}>KONTAKT</div>

                                <div className={styles.columnInfo}>
                                    Browar Koło
                                </div>
                                <div className={styles.columnLink}>
                                    <a href="https://goo.gl/maps/Qq7qVwT6wXh4cRQK9" target="_blank">
                                        ul. Henryka Sienkiewicza 35
                                        <br/>
                                        62-600 Koło
                                    </a>
                                </div>
                                <div className={styles.columnLink}>
                                    <a href="mailto:kontakt@browarkolo.pl">kontakt@browarkolo.pl</a>
                                </div>
                                <div className={styles.columnLink}>
                                    <a href="tel:+48 535 76 76 76">+48 535 76 76 76</a>
                                </div>
                                <div className={styles.socialIconsRow}>
                                    <a href="https://www.facebook.com/browarkolo" aria-label="social media link">
                                        <span className={"icon-social-facebook-icon"}></span>
                                    </a>
                                    <a href="https://www.instagram.com/browarkolo"
                                       aria-label="social media link">
                                        <span className={"icon-social-instagram-icon"}></span>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <VerticalSpacingRow/>
            </Container>
        </div>

    </>
}
